import { action } from "easy-peasy";
import { defaultDocumentState } from "./state";

const actions = {
  setClientDocumentAction: action((state, props) => {
    state.client.document = {
      ...state.client.document,
      ...props,
    };
  }),
  resetClientDocumentAction: action((state) => {
    state.client.document = defaultDocumentState;
  }),
};

export default actions;
