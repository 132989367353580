import { axios } from ".";

const authAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/auth`,
});

function loginAPICall(data) {
  return authAxios({ method: "post", url: "/login", data });
}

function refreshTokenAPICall(data) {
  return authAxios({ method: "post", url: "/token/refresh", data });
}

function resetPasswordAPICall(data) {
  return authAxios({ method: "post", url: "/password/reset", data });
}

export { authAxios, loginAPICall, refreshTokenAPICall, resetPasswordAPICall };
