import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Form from "../Form";
import Button from "../Button";

function useOnHide() {
  const { setSettingAction } = useStoreActions((actions) => actions);
  return () => setSettingAction({ showAddSettingModal: false });
}

function ModalSetting(props) {
  const { showAddSettingModal } = useStoreState((state) => state.setting);

  const onHide = useOnHide();

  return (
    <Modal
      title="Add New Setting"
      show={showAddSettingModal}
      footer={<Button.SaveSetting />}
      onHide={onHide}
      {...props}
    >
      <Form.Setting />
    </Modal>
  );
}

export default ModalSetting;
