import Form from "../../presentational/Form";
import FormFile from "./FormFile";
import FormLogin from "./FormLogin";
import FormPasswordReset from "./FormPasswordReset";
import FormSetting from "./FormSetting";

Form.File = FormFile;
Form.Setting = FormSetting;
Form.Login = FormLogin;
Form.PasswordReset = FormPasswordReset;
export default Form;
