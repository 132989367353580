import { Form } from "react-bootstrap";
import Field from ".";
import tw, { styled } from "twin.macro";

const SForm = styled(Form)`
  max-width: 400px;
  ${tw`w-full`}
`;

function SearchField({
  onChange,
  value,
  label,
  placeholder,
  children,
  ...props
}) {
  return (
    <SForm {...props}>
      {children || (
        <Field
          type="search"
          onChange={onChange}
          value={value}
          label={label}
          placeholder={placeholder}
        />
      )}
    </SForm>
  );
}

export default SearchField;
