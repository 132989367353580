import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";
import { removeAuthToken } from "../../../utilities/api";

function useOnClick() {
  const { setIsLoggedInAction } = useStoreActions((actions) => actions);

  return () => {
    removeAuthToken();
    setIsLoggedInAction(false);
  };
}

function ButtonLogout({ children = "Sign Out", ...props }) {
  const onClick = useOnClick();
  return (
    <Button onClick={onClick} variant="light" {...props}>
      {children}
    </Button>
  );
}

export default ButtonLogout;
