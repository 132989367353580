import tw, { styled } from "twin.macro";
import SearchResultsControl from "./SearchResultsControl";
import SearchResultsHeader from "./SearchResultsHeader";
import SearchResultsItem from "./SearchResultsItem";
import SearchResultsMainText from "./SearchResultsMainText";
import SearchResultsMessage from "./SearchResultsMessage";
import SearchResultsSubText from "./SearchResultsSubText";

const Container = styled.div`
  max-width: 1000px;
  ${tw`mx-auto`}
`;

function SearchResults({ children, header = "Results", ...props }) {
  return (
    <Container {...props}>
      {header && <SearchResultsHeader>{header}</SearchResultsHeader>}
      {children}
    </Container>
  );
}

SearchResults.MainText = SearchResultsMainText;
SearchResults.SubText = SearchResultsSubText;
SearchResults.Header = SearchResultsHeader;
SearchResults.Message = SearchResultsMessage;
SearchResults.Item = SearchResultsItem;
SearchResults.Control = SearchResultsControl;
export default SearchResults;
