import "bootstrap/dist/css/bootstrap.min.css";
import store from "../../store";
import { StoreProvider } from "easy-peasy";
import Home from "../pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PasswordReset from "../pages/PasswordReset";
import AddDocuments from "../pages/AddDocuments";
import "../../global/global";
import { links } from "../../utilities";
import { axios } from "../../api";
import { authAxios } from "../../api/auth";
import { clientAxios } from "../../api/client";
import { fileAxios } from "../../api/file";
import { settingAxios } from "../../api/setting";
import { userAxios } from "../../api/user";
import { axiosInterceptor } from "../../utilities/api";
import { documentAxios } from "../../api/document";

function App() {
  const { HOME, PASSWORD_RESET, ADD_NEW_DOCUMENTS } = links;

  axiosInterceptor(authAxios, store);
  axiosInterceptor(clientAxios, store);
  axiosInterceptor(documentAxios, store);
  axiosInterceptor(axios, store);
  axiosInterceptor(fileAxios, store);
  axiosInterceptor(settingAxios, store);
  axiosInterceptor(userAxios, store);

  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path={HOME} element={<Home />} />
          <Route path={PASSWORD_RESET} element={<PasswordReset />} />
          <Route path={ADD_NEW_DOCUMENTS} element={<AddDocuments />} />
        </Routes>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
