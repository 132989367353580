import { useStoreState } from "easy-peasy";
import Document from "../../presentational/Document";
import Accordion from "../Accordion";
import DocumentFormUser from "./DocumentFormUser";
import DocumentFormClient from "./DocumentFormClient";
import DocumentFormFile from "./DocumentFormFile";
import DocumentFormSetting from "./DocumentFormSetting";

function DocumentForm({ children, eventKey, ...props }) {
  const { activeDocuments } = useStoreState((state) => state);
  return (
    <Document.Form {...props}>
      {activeDocuments.includes(eventKey) && <Document.Separator />}
      <Accordion.Body eventKey={eventKey}>{children}</Accordion.Body>
    </Document.Form>
  );
}

DocumentForm.File = DocumentFormFile;
DocumentForm.User = DocumentFormUser;
DocumentForm.Client = DocumentFormClient;
DocumentForm.Setting = DocumentFormSetting;
export default DocumentForm;
