const defaultState = {
  searchInput: "",
  searchResults: [],
  showAddFileModal: false,
  document: {
    name: "",
    content: "// Type in content here",
    _id: "",
  },
};

const state = {
  file: defaultState,
};

export { state as default, defaultState };
