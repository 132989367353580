import { Accordion as RBAccordion } from "react-bootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import AccordionButton from "./AccordionButton";
import AccordionBody from "./AccordionBody";

function Accordion({ children, ...props }) {
  const { activeDocuments } = useStoreState((state) => state);
  const { toggleDocumentThunk } = useStoreActions((actions) => actions);

  return (
    <RBAccordion
      activeKey={activeDocuments}
      onSelect={(eventKey, event) => toggleDocumentThunk(eventKey)}
      {...props}
    >
      {children}
    </RBAccordion>
  );
}

Accordion.Body = AccordionBody;
Accordion.Button = AccordionButton;
export default Accordion;
