import { thunk } from "easy-peasy";
import {
  createDocumentsAPICall,
  getDocumentsAPICall,
  updateDocumentsAPICall,
} from "../../api/document";
import { getFileAPICall } from "../../api/file";
import { getSettingAPICall } from "../../api/setting";

const thunks = {
  toggleDocumentThunk: thunk(async (actions, docIndex, helpers) => {
    if (helpers.getState().activeDocuments.includes(docIndex))
      actions.collapseDocumentAction(docIndex);
    else actions.expandDocumentAction(docIndex);
  }),
  getDocumentsThunk: thunk(async (actions, guid, helpers) => {
    actions.setLoadingAction(true);

    try {
      const documentsResponse = await getDocumentsAPICall(guid);
      actions.setUserDocumentAction(documentsResponse.data.user);
      actions.setClientDocumentAction(documentsResponse.data.client);
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  updateDocumentsThunk: thunk(
    async (
      actions,
      { userInput, clientInput, settingInput, fileInput },
      helpers
    ) => {
      actions.setLoadingAction(true);

      try {
        const updatedDocumentsResponse = await updateDocumentsAPICall({
          user: userInput,
          client: clientInput,
          setting: settingInput,
          file: fileInput,
        });
        updatedDocumentsResponse.data.user._id &&
          actions.setAlertThunk({
            type: "success",
            message: "User updated",
          });
        updatedDocumentsResponse.data.client._id &&
          actions.setAlertThunk({
            type: "success",
            message: "Client updated",
          });
        updatedDocumentsResponse.data.setting._id &&
          actions.setAlertThunk({
            type: "success",
            message: "Setting updated",
          });
        updatedDocumentsResponse.data.file._id &&
          actions.setAlertThunk({
            type: "success",
            message: "File updated",
          });

        const [userClientResponse, settingResponse, fileResponse] =
          await Promise.all([
            getDocumentsAPICall(updatedDocumentsResponse.data.client.guid),
            updatedDocumentsResponse.data.setting._id
              ? getSettingAPICall(updatedDocumentsResponse.data.setting._id)
              : Promise.resolve({ data: settingInput }),
            updatedDocumentsResponse.data.file._id
              ? getFileAPICall(updatedDocumentsResponse.data.file._id)
              : Promise.resolve({ data: fileInput }),
          ]);
        actions.setUserDocumentAction(userClientResponse.data.user);
        actions.setClientDocumentAction(userClientResponse.data.client);
        actions.setSettingDocumentAction(settingResponse.data);
        actions.setFileDocumentAction(fileResponse.data);
      } catch (e) {
        actions.setAlertThunk({
          type: "error",
          message: e.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  createDocumentsThunk: thunk(
    async (actions, { navigate, user, client }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const documentsResponse = await createDocumentsAPICall({
          user,
          client,
        });
        actions.setUserDocumentAction(documentsResponse.data.user);
        actions.setClientDocumentAction(documentsResponse.data.client);
        navigate("/");
      } catch (e) {
        actions.setAlertThunk({
          type: "error",
          message: e.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
