import { action } from "easy-peasy";

const actions = {
  setActiveDocumentsAction: action((state, indexes) => {
    state.activeDocuments = indexes;
  }),
  expandDocumentAction: action((state, index) => {
    state.activeDocuments = [...state.activeDocuments, index];
  }),
  collapseDocumentAction: action((state, index) => {
    state.activeDocuments = state.activeDocuments.filter(
      (docIndex) => docIndex !== index
    );
  }),
};

export default actions;
