const defaultDocumentState = {
  name: "",
  email: "",
  password: "",
  role: "",
  clientGuid: "",
  jwtToken: "",
  _id: "",
};

const state = {
  user: {
    document: defaultDocumentState,
    searchResults: [],
    searchInput: "",
    hasSearched: false,
  },
};

export { state as default, defaultDocumentState };
