import { Field } from "@cfbs/cfbsstrap";
import tw from "twin.macro";

const Required = tw.span`text-red-400`;

function FormField({ children, label = "", required = false, ...props }) {
  return (
    <Field {...props}>
      <Field.Label>
        {label}
        {required && <Required> *</Required>}
      </Field.Label>
      {children}
    </Field>
  );
}

export default FormField;
