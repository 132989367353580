import { action } from "easy-peasy";

const actions = {
  setFileAction: action((state, file) => {
    state.file = {
      ...state.file,
      ...file,
    };
  }),
  setFileDocumentAction: action((state, document) => {
    state.file.document = {
      ...state.file.document,
      ...document,
    };
  }),
};

export default actions;
