import { Navbar, Nav } from "react-bootstrap";
import { links } from "../../utilities/index";
import Button from "../containers/Button";
import tw from "twin.macro";

const SNavbar = tw(Navbar)`px-5`;

function TopNavbar({ navLinks = [], ...props }) {
  return (
    <SNavbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      sticky="top"
      {...props}
    >
      <Navbar.Brand href={links.HOME}>Kiosk Manager</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav>
          {navLinks.map(({ label, ...rest }, index) => (
            <Nav.Link key={index} {...rest}>
              {label}
            </Nav.Link>
          ))}
        </Nav>
        <Nav className="ms-auto">
          <Button.Logout />
        </Nav>
      </Navbar.Collapse>
    </SNavbar>
  );
}

export default TopNavbar;
