import { useStoreActions, useStoreState } from "easy-peasy";
import Field from "../../presentational/Field";
import Accordion from "../Accordion";
import { useEffect } from "react";
import DocumentForm from ".";

function useClientFields() {
  const {
    erp: { erps },
    client,
  } = useStoreState((state) => state);
  const { setClientDocumentAction } = useStoreActions((actions) => actions);

  const fields = [
    {
      type: "select",
      options: erps.map(({ name }) => ({
        children: name,
        value: name.toLowerCase(),
      })),
      stateProp: "erp",
      label: "ERP",
    },
    {
      stateProp: "autoLogoutMinutes",
      label: "Auto Logout Minutes",
    },
    {
      stateProp: "baseURL",
      label: "Base URL",
      col: { xs: 12 },
    },
    {
      stateProp: "acmClientID",
      label: "ACM Client ID",
      col: { xs: 12 },
    },
    {
      stateProp: "acmUser",
      label: "ACM User",
    },
    {
      stateProp: "acmPwd",
      label: "ACM Password",
    },
    {
      stateProp: "acmSecret",
      label: "ACM Secret",
    },
    {
      stateProp: "acmCompanyKey",
      label: "ACM Company Key",
    },
    {
      stateProp: "centrikUser",
      label: "Centrik User",
    },
    {
      stateProp: "centrikPwd",
      label: "Centrik Password",
    },
    {
      stateProp: "centrikSecret",
      label: "Centrik Secret",
    },
    {
      stateProp: "spinner",
      label: "Spinner",
    },
    {
      stateProp: "tenant",
      label: "Tenant",
      col: { xs: 12, md: 6, lg: 4 },
    },
    {
      stateProp: "logo",
      label: "Logo",
      col: { xs: 12, md: 6, lg: 4 },
    },
    {
      stateProp: "favicon",
      label: "Favicon",
      col: { xs: 12, md: 6, lg: 4 },
    },
    {
      stateProp: "centrikBaseURL",
      label: "Centrik Base URL",
      col: { xs: 12 },
    },
  ].map(({ stateProp, ...field }) => ({
    value: client.document[stateProp],
    onChange: (evt) =>
      setClientDocumentAction({ [stateProp]: evt.target.value }),
    col: { xs: 12, md: 6 },
    ...field,
  }));

  return fields;
}

function useClientSwitchFields() {
  const clientDocument = useStoreState((state) => state.client.document);
  const { setClientDocumentAction } = useStoreActions((actions) => actions);
  return [
    {
      stateProp: "phoneHome",
      label: "Phone Home",
    },
    {
      stateProp: "autoLogout",
      label: "Auto Logout",
    },
  ].map(({ stateProp, ...field }) => ({
    col: { xs: 12, md: 6 },
    checked: clientDocument ? clientDocument[stateProp] : false,
    onChange: (evt) =>
      setClientDocumentAction({ [stateProp]: evt.target.checked }),
    ...field,
    type: "switch",
  }));
}

function useKioskConfigField() {
  const kioskConfig = useStoreState(
    (state) => state.client.document.kioskConfig
  );
  const { setClientDocumentAction } = useStoreActions((actions) => actions);
  return {
    placeholder: kioskConfig,
    onChange: (evt) => {
      setClientDocumentAction({ kioskConfig: evt.jsObject });
    },
  };
}

function DocumentFormClient({ eventKey = "1", ...props }) {
  const clientFields = useClientFields();
  const switchFields = useClientSwitchFields();
  const kioskConfigField = useKioskConfigField();

  const { erps } = useStoreState((state) => state.erp);
  const { getAllErpsThunk } = useStoreActions((actions) => actions);

  useEffect(() => !erps.length && getAllErpsThunk(), [getAllErpsThunk, erps]);

  return (
    <DocumentForm
      header={
        <Accordion.Button eventKey={eventKey}>Client Document</Accordion.Button>
      }
      eventKey={eventKey}
      {...props}
    >
      <Field.List fields={clientFields.concat(switchFields)} />
      <Field type="json" label="Kiosk Configuration" {...kioskConfigField} />
    </DocumentForm>
  );
}

export default DocumentFormClient;
