import { useStoreActions, useStoreState } from "easy-peasy";
import SearchResults from "../../presentational/SearchResults";

function useOnClickItem() {
  const { getDocumentsThunk, setActiveDocumentsAction } = useStoreActions(
    (actions) => actions
  );

  return (clientGuid) => () => {
    getDocumentsThunk(clientGuid);
    setActiveDocumentsAction(["0"]);
  };
}

function SearchResultsUser(props) {
  const searchResults = useStoreState(
    (state) => state.user.searchResults || []
  );

  const onClickItem = useOnClickItem();

  return (
    <SearchResults {...props}>
      {searchResults.map((user, index) => (
        <SearchResults.Item key={index} onClick={onClickItem(user.clientGuid)}>
          <SearchResults.MainText>{user.name}</SearchResults.MainText>
        </SearchResults.Item>
      ))}
    </SearchResults>
  );
}

export default SearchResultsUser;
