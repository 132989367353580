import Field from "../../presentational/Field";
import { styled } from "twin.macro";
import { useStoreActions, useStoreState } from "easy-peasy";

const SField = styled(Field)`
  height: 65px;
  font-size: 1.5rem;
`;

function useOnSubmit() {
  const { searchInput } = useStoreState((state) => state.user);

  const { searchUsersThunk } = useStoreActions((actions) => actions);

  return (evt) => {
    evt.preventDefault();
    searchUsersThunk(searchInput);
  };
}

function useOnChange() {
  const { setSearchInputAction } = useStoreActions((actions) => actions);
  return (evt) => {
    evt.preventDefault();
    setSearchInputAction(evt.target.value);
  };
}

function SearchFieldUser(props) {
  const { searchInput } = useStoreState((state) => state.user);

  const onSubmit = useOnSubmit();
  const onChange = useOnChange();

  return (
    <Field.Search onSubmit={onSubmit} {...props}>
      <SField
        type="search"
        onChange={onChange}
        placeholder="Enter client name"
        value={searchInput}
      />
    </Field.Search>
  );
}

export default SearchFieldUser;
