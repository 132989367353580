import { thunk } from "easy-peasy";
import {
  upsertSettingAPICall,
  searchSettingsAPICall,
  getSettingAPICall,
} from "../../api/setting";
import { defaultState as defaultSettingState } from "./state";
import { defaultState as defaultFileState } from "../file/state";

const thunks = {
  createSettingThunk: thunk(
    async (actions, { successMsg, ...setting }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const response = await upsertSettingAPICall(setting);
        actions.setSettingDocumentAction(response.data);
        actions.setSettingAction({ showAddSettingModal: false });
        actions.setFileAction(defaultFileState);
        actions.setAlertThunk({
          type: "success",
          message: successMsg,
        });
      } catch (e) {
        console.log(e);
        actions.setAlertThunk({
          type: "error",
          message: e.response.data.message,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  searchSettingsThunk: thunk(async (actions, queryJson, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await searchSettingsAPICall(queryJson);
      actions.setSettingAction({
        ...defaultSettingState,
        searchResults: response.data,
      });
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  getSettingThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getSettingAPICall(id);
      actions.setSettingDocumentAction(response.data);
      actions.setFileAction(defaultFileState);
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
