import { useStoreActions, useStoreState } from "easy-peasy";
import SearchResults from "../../presentational/SearchResults";

function SearchResultsFile(props) {
  const { searchResults } = useStoreState((state) => state.file);
  const { getFileThunk } = useStoreActions((actions) => actions);

  return (
    <SearchResults {...props}>
      {searchResults.map(({ _id, name }) => (
        <SearchResults.Item key={_id} onClick={() => getFileThunk(_id)}>
          <SearchResults.MainText>{name}</SearchResults.MainText>
        </SearchResults.Item>
      ))}
    </SearchResults>
  );
}

export default SearchResultsFile;
