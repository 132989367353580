import { thunk } from "easy-peasy";
import { searchUsersAPICall } from "../../api/user";

const thunks = {
  searchUsersThunk: thunk(async (actions, name, helpers) => {
    actions.setLoadingAction(true);

    try {
      const searchResults = await searchUsersAPICall(name);
      actions.setSearchResultsAction(searchResults.data);
      actions.resetUserDocumentAction();
      actions.resetClientDocumentAction();
    } catch (e) {
      actions.setSearchResultsAction(null);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
