import { thunk } from "easy-peasy";
import { confirmAcumaticaAPICall } from "../../api/client";

const thunks = {
  confirmAcumaticaThunk: thunk(async (actions, clientGuid, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await confirmAcumaticaAPICall(clientGuid);
      actions.setClientDocumentAction({
        acumaticaConfirmed: true,
      });
      actions.setAlertThunk({
        type: "success",
        message: response.data,
      });
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
