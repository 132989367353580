import { useStoreActions, useStoreState } from "easy-peasy";
import Field from "../../presentational/Field";

function useOnSubmit() {
  const { file, setting } = useStoreState((state) => state);

  const { searchFilesThunk } = useStoreActions((actions) => actions);

  return (evt) => {
    evt.preventDefault();
    searchFilesThunk({
      name: file.searchInput,
      settingId: setting.document._id,
    });
  };
}

function useOnChange() {
  const { setFileAction } = useStoreActions((actions) => actions);
  return (evt) => setFileAction({ searchInput: evt.target.value });
}

function SearchFieldFile(props) {
  const { searchInput } = useStoreState((state) => state.file);

  const onChange = useOnChange();
  const onSubmit = useOnSubmit();

  return (
    <Field.Search
      onSubmit={onSubmit}
      onChange={onChange}
      placeholder="Enter file name to search for"
      value={searchInput}
      {...props}
    />
  );
}

export default SearchFieldFile;
