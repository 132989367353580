import { thunk } from "easy-peasy";
import { getAllErpsAPICall } from "../../api/erp";

const thunks = {
  getAllErpsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getAllErpsAPICall();
      actions.setErpAction({
        erps: response.data,
      });
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
