const defaultDocumentState = {
  erp: "",
  guid: "",
  client: "",
  baseURL: "",
  acmUser: "",
  acmPwd: "",
  acmSecret: "",
  acmClientID: "",
  acmCompanyKey: "",
  acumaticaConfirmed: false,
  centrikBaseURL: "",
  centrikSecret: "",
  tenant: "",
  logo: "",
  spinner: "",
  phoneHome: false,
  autoLogoutMinutes: 0,
  autoLogout: false,
  centrikUser: "",
  cenrikPassword: "",
  kioskConfig: {},
  _id: "",
  id: 0,
};

const state = {
  client: {
    document: defaultDocumentState,
  },
};

export { state as default, defaultDocumentState };
