import { Modal as RBModal } from "react-bootstrap";

function Modal({ title, footer, children, ...props }) {
  return (
    <RBModal size="lg" centered {...props}>
      <RBModal.Header closeButton>
        <RBModal.Title>{title}</RBModal.Title>
      </RBModal.Header>

      <RBModal.Body>{children}</RBModal.Body>

      <RBModal.Footer>{footer}</RBModal.Footer>
    </RBModal>
  );
}

export default Modal;
