import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Form from "../Form";
import Button from "../Button";

function useOnHide() {
  const { setFileAction } = useStoreActions((actions) => actions);
  return () => setFileAction({ showAddFileModal: false });
}

function ModalFile(props) {
  const { showAddFileModal } = useStoreState((state) => state.file);

  const onHide = useOnHide();

  return (
    <Modal
      title="Add New File"
      footer={<Button.SaveFile />}
      show={showAddFileModal}
      onHide={onHide}
      {...props}
    >
      <Form.File />
    </Modal>
  );
}

export default ModalFile;
