import { action } from "easy-peasy";

const actions = {
  setSettingAction: action((state, setting) => {
    state.setting = {
      ...state.setting,
      ...setting,
    };
  }),
  setSettingDocumentAction: action((state, document) => {
    state.setting.document = {
      ...state.setting.document,
      ...document,
    };
  }),
  setSettingFileAction: action((state, file) => {
    state.setting.file = {
      ...state.setting.file,
      ...file,
    };
  }),
};

export default actions;
