import { Button, AccordionContext, useAccordionButton } from "react-bootstrap";
import tw from "twin.macro";
import { ChevronDown } from "@styled-icons/boxicons-regular/ChevronDown";
import { ChevronUp } from "@styled-icons/boxicons-regular/ChevronUp";
import { useContext } from "react";

const ToggleButton = tw(Button)`bg-transparent! border-0 text-4xl`;
const CollapseIcon = tw(ChevronUp)`w-6`;
const ExpandIcon = tw(ChevronDown)`w-6`;

function useOnClick({ eventKey, callback }) {
  return useAccordionButton(eventKey, () => callback && callback(eventKey));
}

function AccordionButton({ children, eventKey, callback, ...props }) {
  const { activeEventKey } = useContext(AccordionContext);

  const onClick = useOnClick({ eventKey, callback });

  return (
    <ToggleButton variant="light" onClick={onClick} {...props}>
      {children}
      {activeEventKey.includes(eventKey) ? <CollapseIcon /> : <ExpandIcon />}
    </ToggleButton>
  );
}

export default AccordionButton;
