import { Accordion } from "react-bootstrap";

function AccordionBody({ children, ...props }) {
  return (
    <Accordion.Collapse {...props}>
      <>{children}</>
    </Accordion.Collapse>
  );
}

export default AccordionBody;
