import { Field as CField } from "@cfbs/cfbsstrap";
import FieldList from "./FieldList";
import Form from "../Form";
import tw from "twin.macro";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import SearchField from "./FieldSearch";

const Container = tw(Form.Group)`py-2 w-full`;

function Field({ label, type = "text", required, ...props }) {
  let component = <></>;

  if (type === "switch") component = <CField.Switch label={label} {...props} />;
  else if (type === "select")
    component = (
      <Form.Field label={label} required={required}>
        <CField.Dropdown {...props} />
      </Form.Field>
    );
  else if (type === "json")
    component = (
      <Form.Field label={label} required={required}>
        <JSONInput width="100%" locale={locale} {...props} />
      </Form.Field>
    );
  else
    component = (
      <Form.Field label={label} required={required}>
        <CField.Input type={type} {...props} />
      </Form.Field>
    );

  return <Container>{component}</Container>;
}

Field.List = FieldList;
Field.Search = SearchField;
export default Field;
