import actions from "./actions";
import state from "./state";
import thunks from "./thunks";

const store = {
  ...state,
  ...actions,
  ...thunks,
};

export default store;
