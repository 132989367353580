import { action } from "easy-peasy";
import { defaultDocumentState } from "./state";

const actions = {
  setUserDocumentAction: action((state, props) => {
    state.user.document = {
      ...state.user.document,
      ...props,
    };
  }),
  setSearchResultsAction: action((state, searchResults) => {
    state.user.searchResults = searchResults;
    state.user.hasSearched = true;
  }),
  setSearchInputAction: action((state, input) => {
    state.user.searchInput = input;
  }),
  resetUserDocumentAction: action((state) => {
    state.user.document = defaultDocumentState;
  }),
};

export default actions;
