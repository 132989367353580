import DocumentHeader from "./DocumentHeader";

function DocumentForm({ children, header, ...props }) {
  return (
    <div {...props}>
      <DocumentHeader>{header}</DocumentHeader>
      {children}
    </div>
  );
}

export default DocumentForm;
