import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";
import { defaultState as defaultSettingState } from "../../../store/setting/state";

function useOnClick() {
  const { setSettingDocumentAction, setSettingAction } = useStoreActions(
    (actions) => actions
  );

  return () => {
    setSettingDocumentAction(defaultSettingState.document);
    setSettingAction({ showAddSettingModal: true });
  };
}

function ButtonAddSetting({ children = "Add New Setting", ...props }) {
  const onClick = useOnClick();
  return (
    <Button onClick={onClick} {...props}>
      {children}
    </Button>
  );
}

export default ButtonAddSetting;
