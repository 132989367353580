import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";
import { defaultState as defaultFileState } from "../../../store/file/state";

function useOnClick() {
  const { setFileDocumentAction, setFileAction } = useStoreActions(
    (actions) => actions
  );

  return () => {
    setFileDocumentAction(defaultFileState.document);
    setFileAction({ showAddFileModal: true });
  };
}

function ButtonAddFile({ children = "Add New File", ...props }) {
  const onClick = useOnClick();

  return (
    <Button onClick={onClick} {...props}>
      Add New File
    </Button>
  );
}

export default ButtonAddFile;
