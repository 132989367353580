import { axios } from ".";

const userAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/user`,
});

function searchUsersAPICall(searchInput) {
  return userAxios({ method: "get", url: `/search?name=${searchInput}` });
}

export { userAxios, searchUsersAPICall };
