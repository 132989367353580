import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";
import Page from "../containers/Page";
import Center from "../presentational/Center";
import Form from "../containers/Form";

function useOnLoad() {
  const { setUserDocumentAction } = useStoreActions((actions) => actions);

  useEffect(
    () => setUserDocumentAction({ password: "", cNewPassword: "" }),
    [setUserDocumentAction]
  );
}

function PasswordReset() {
  useOnLoad();

  return (
    <Page.Protected>
      <Center.Screen>
        <Form.PasswordReset />
      </Center.Screen>
    </Page.Protected>
  );
}

export default PasswordReset;
