import { axios } from ".";

const settingAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/setting`,
});

function upsertSettingAPICall(data) {
  return settingAxios({ method: "put", url: "/upsert", data });
}

function searchSettingsAPICall({ name, clientGuid }) {
  return settingAxios({
    method: "get",
    url: `/search?name=${name}&clientGuid=${clientGuid}`,
  });
}

function getSettingAPICall(id) {
  return settingAxios({ method: "get", url: `/get/${id}` });
}

export {
  settingAxios,
  upsertSettingAPICall,
  searchSettingsAPICall,
  getSettingAPICall,
};
