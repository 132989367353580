import { useStoreState } from "easy-peasy";
import tw from "twin.macro";
import LoaderSpin from "../../presentational/LoaderSpin";
import Center from "../../presentational/Center";
import { Alert } from "react-bootstrap";
import PageProtected from "./PageProtected";

const Container = tw.div`w-screen min-h-screen`;
const AlertContainer = tw.div`fixed bottom-12`;

function Page({ children, ...props }) {
  const { loading, alerts } = useStoreState((state) => state.status);
  return (
    <Container {...props}>
      <AlertContainer>
        {alerts.map((alert) => (
          <Alert
            key={alert.id}
            variant={alert.type === "success" ? "success" : "danger"}
          >
            {alert.message}
          </Alert>
        ))}
      </AlertContainer>

      {loading ? (
        <Center.Screen>
          <LoaderSpin />
        </Center.Screen>
      ) : (
        children
      )}
    </Container>
  );
}

Page.Protected = PageProtected;
export default Page;
