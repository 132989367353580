import { useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function useIsDisabled() {
  function sanitize(password) {
    return password.trim();
  }

  const passwordResetInput = useStoreState(
    (state) => state.auth.passwordResetInput
  );

  const password = sanitize(passwordResetInput.password);
  const cpassword = sanitize(passwordResetInput.cpassword);

  return !password || password !== cpassword;
}

function ButtonPasswordReset({ children = "Reset Password", ...props }) {
  const disabled = useIsDisabled();
  return (
    <Button type="submit" variant="danger" disabled={disabled} {...props}>
      {children}
    </Button>
  );
}

export default ButtonPasswordReset;
