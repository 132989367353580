import { axios } from ".";

const fileAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/file`,
});

function upsertFileAPICall(data) {
  return fileAxios({ method: "put", url: "/upsert", data });
}

function searchFilesAPICall({ name, settingId }) {
  return fileAxios({
    method: "get",
    url: `/search?name=${name}&settingId=${settingId}`,
  });
}

function getFileAPICall(id) {
  return fileAxios({ method: "get", url: `/get/${id}` });
}

export { fileAxios, upsertFileAPICall, searchFilesAPICall, getFileAPICall };
