import { useStoreState } from "easy-peasy";
import Accordion from "../Accordion";
import Form from "../Form";
import FormView from "../../presentational/Form";
import Button from "../Button";
import SearchField from "../SearchField";
import SearchResults from "../../presentational/SearchResults";
import DocumentForm from ".";

function DocumentFormSetting({ eventKey = "2", ...props }) {
  const {
    document: { _id },
    searchResults,
  } = useStoreState((state) => state.setting);

  return (
    <DocumentForm
      header={
        <Accordion.Button eventKey={eventKey}>
          Setting Document
        </Accordion.Button>
      }
      eventKey={eventKey}
      {...props}
    >
      <br />

      <SearchResults.Control>
        <SearchField.Setting />
        <Button.AddSetting />
      </SearchResults.Control>

      <br />

      {!_id && searchResults.length > 0 && <SearchResults.Setting />}

      {_id && (
        <>
          <FormView.Separator />
          <Form.Setting />
        </>
      )}
    </DocumentForm>
  );
}

export default DocumentFormSetting;
