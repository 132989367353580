import { useStoreActions, useStoreState } from "easy-peasy";
import Field from "../../presentational/Field";

function useSettingFields() {
  const { name, baseURLDev, baseURLProd } = useStoreState(
    (state) => state.setting.document
  );
  const { setSettingDocumentAction } = useStoreActions((actions) => actions);

  const fields = [
    {
      label: "Name",
      value: name,
      onChange: (evt) => setSettingDocumentAction({ name: evt.target.value }),
      col: { xs: 12 },
      required: true,
    },
    {
      label: "Backend Base Url (Development)",
      value: baseURLDev,
      onChange: (evt) =>
        setSettingDocumentAction({ baseURLDev: evt.target.value }),
    },
    {
      label: "Backend Base Url (Production)",
      value: baseURLProd,
      onChange: (evt) =>
        setSettingDocumentAction({ baseURLProd: evt.target.value }),
    },
  ].map(({ stateProp, ...field }) => ({
    col: { xs: 12, md: 6 },
    ...field,
  }));

  return fields;
}

function FormSetting(props) {
  const fields = useSettingFields();
  return <Field.List fields={fields} {...props} />;
}

export default FormSetting;
