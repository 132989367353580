import { action } from "easy-peasy";

const actions = {
  setLoadingAction: action((state, isLoading) => {
    state.status.loading = isLoading;
  }),
  addAlertAction: action((state, { id, type, message }) => {
    state.status.alerts = [...state.status.alerts, { id, type, message }];
  }),
  removeAlertAction: action((state, id) => {
    state.status.alerts = state.status.alerts.filter(
      (alert) => alert.id !== id
    );
  }),
};

export default actions;
