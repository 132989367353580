import { axios } from ".";

const documentAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/documents`,
});

function createDocumentsAPICall(data) {
  return documentAxios({ method: "post", url: "/create", data });
}

function updateDocumentsAPICall(data) {
  return documentAxios({ method: "put", url: "/update", data });
}

function getDocumentsAPICall(guid) {
  return documentAxios({ method: "get", url: `/get/${guid}` });
}

export {
  documentAxios,
  createDocumentsAPICall,
  updateDocumentsAPICall,
  getDocumentsAPICall,
};
