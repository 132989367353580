import { axios } from ".";

const clientAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/client`,
});

function confirmAcumaticaAPICall(clientGuid) {
  return clientAxios({ method: "put", url: `/${clientGuid}/acumatica/verify` });
}

export { clientAxios, confirmAcumaticaAPICall };
