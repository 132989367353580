const state = {
  auth: {
    isLoggedIn: localStorage.getItem("token") ? true : false,
    loginInput: {
      email: "",
      password: "",
    },
    passwordResetInput: {
      password: "",
      cpassword: "",
    },
  },
};

export default state;
