import { useStoreActions, useStoreState } from "easy-peasy";
import SearchResults from "../containers/SearchResults";
import SearchResultsView from "../presentational/SearchResults";
import Document from "../presentational/Document";
import { styled } from "twin.macro";
import Page from "../containers/Page";
import Center from "../presentational/Center";
import SearchField from "../containers/SearchField";
import Field from "../presentational/Field";
import { Col, Row } from "react-bootstrap";
import DocumentForm from "../containers/DocumentForm";
import DocumentHeader from "../containers/DocumentHeader";
import Button from "../containers/Button";
import Modal from "../containers/Modal";
import Accordion from "../containers/Accordion";
import { useNavigate } from "react-router-dom";
import { links } from "../../utilities";

const SearchResultsMessage = styled(SearchResultsView.Message)`
  margin-top: 12%;
`;

function useUserReadOnlyFields() {
  const { clientGuid, jwtToken } = useStoreState(
    (state) => state.user.document
  );
  return [
    {
      label: "Client GUID",
      value: clientGuid,
    },
    {
      label: "JSON Web Token (JWT)",
      value: jwtToken,
    },
  ].map((field) => ({ col: { xs: 12 }, ...field, disabled: true }));
}

function useButtons() {
  const navigate = useNavigate();
  const { user, client, setting, file } = useStoreState((state) => state);
  const { updateDocumentsThunk } = useStoreActions((actions) => actions);

  return [
    {
      children: "Reset User Password",
      onClick: () =>
        navigate(`${links.PASSWORD_RESET}?user=${user.document.clientGuid}`),
    },
    {
      children: "Update",
      onClick: () =>
        updateDocumentsThunk({
          userInput: user.document,
          clientInput: client.document,
          settingInput: setting.document,
          fileInput: file.document,
        }),
    },
  ].map((btn) => ({
    type: "button",
    size: "lg",
    col: { md: 6 },
    ...btn,
  }));
}

function Home() {
  const { user, client, setting } = useStoreState((state) => state);

  const userReadOnlyFields = useUserReadOnlyFields();
  const buttons = useButtons();

  return (
    <Page.Protected>
      <Document>
        <Center>
          <SearchField.User />
        </Center>

        <br />

        {user.searchResults.length > 0 && !user.document._id && (
          <SearchResults.User />
        )}

        {user.hasSearched && !user.searchResults.length && (
          <Center>
            <SearchResultsMessage>
              No users or clients found with that name
            </SearchResultsMessage>
          </Center>
        )}

        {user.document._id && (
          <>
            <br />

            <Accordion>
              {user.document._id && (
                <DocumentForm.User>
                  <Field.List fields={userReadOnlyFields} />
                </DocumentForm.User>
              )}
              <br />
              {client.document._id && (
                <DocumentForm.Client header={<DocumentHeader.Client />} />
              )}
              <br />
              {user.document._id && client.document._id && (
                <DocumentForm.Setting />
              )}
              <br />
              {setting.document._id && <DocumentForm.File />}
            </Accordion>

            <br />

            <Row>
              {user.document._id &&
                client.document._id &&
                buttons.map(({ col, children, ...props }, index) => (
                  <Col key={index} {...col}>
                    <Button {...props}>{children}</Button>
                  </Col>
                ))}
            </Row>
          </>
        )}
      </Document>

      <Modal.Setting />
      <Modal.File />
    </Page.Protected>
  );
}

export default Home;
