import { action } from "easy-peasy";

const actions = {
  setErpAction: action((state, erp) => {
    state.erp = {
      ...state.erp,
      ...erp,
    };
  }),
};

export default actions;
