import { thunk } from "easy-peasy";
import {
  upsertFileAPICall,
  searchFilesAPICall,
  getFileAPICall,
} from "../../api/file";
import { defaultState } from "./state";

const thunks = {
  createFileThunk: thunk(
    async (actions, { successMsg, ...fileObj }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const response = await upsertFileAPICall(fileObj);
        actions.setFileDocumentAction(response.data);
        actions.setFileAction({ showAddFileModal: false });
        actions.setAlertThunk({
          type: "success",
          message: successMsg,
        });
      } catch (e) {
        console.log(e);
        actions.setAlertThunk({
          type: "error",
          message: e.response.data.message,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  searchFilesThunk: thunk(async (actions, queryJson, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await searchFilesAPICall(queryJson);
      actions.setFileAction({ searchResults: response.data });
      actions.setFileDocumentAction(defaultState.document);
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  getFileThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getFileAPICall(id);
      actions.setFileDocumentAction(response.data);
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
