import { useStoreState } from "easy-peasy";
import { links } from "../../../utilities";
import TopNavbar from "../../presentational/TopNavbar";
import Login from "../Login";
import Page from ".";

const navLinks = [
  {
    href: links.HOME,
    label: "Home",
  },
  {
    href: links.ADD_NEW_DOCUMENTS,
    label: "Add New Documents",
  },
];

function PageProtected({ children, ...props }) {
  const isLoggedIn = useStoreState((state) => state.auth.isLoggedIn);
  return (
    <Page {...props}>
      {isLoggedIn ? (
        <>
          <TopNavbar navLinks={navLinks} />
          {children}
        </>
      ) : (
        <Login />
      )}
    </Page>
  );
}

export default PageProtected;
