import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";
import Page from "./Page";
import Center from "../presentational/Center";
import { Form } from "react-bootstrap";

function useOnLoad() {
  const { setUserDocumentAction } = useStoreActions((actions) => actions);

  useEffect(
    () => setUserDocumentAction({ email: "", password: "" }),
    [setUserDocumentAction]
  );
}

function Login() {
  useOnLoad();

  return (
    <Page>
      <Center.Screen>
        <Form.Login />
      </Center.Screen>
    </Page>
  );
}

export default Login;
