import { createStore } from "easy-peasy";
import authStore from "./auth";
import clientStore from "./client";
import userStore from "./user";
import statusStore from "./status";
import settingStore from "./setting";
import fileStore from "./file";
import erpStore from "./erp";
import documentStore from "./document";

const stateStore = createStore({
  ...userStore,
  ...clientStore,
  ...statusStore,
  ...authStore,
  ...settingStore,
  ...fileStore,
  ...erpStore,
  ...documentStore,
});

export default stateStore;
