import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function useOnClick() {
  const { guid } = useStoreState((state) => state.client.document);

  const { confirmAcumaticaThunk } = useStoreActions((actions) => actions);

  return () => confirmAcumaticaThunk(guid);
}

function ButtonAcumaticaUnconfirmed({ children = "Test", ...props }) {
  const onClick = useOnClick();

  return (
    <Button variant="warning" onClick={onClick} {...props}>
      {children}
    </Button>
  );
}

export default ButtonAcumaticaUnconfirmed;
