import { action } from "easy-peasy";

const actions = {
  setIsLoggedInAction: action((state, isLoggedIn) => {
    state.auth.isLoggedIn = isLoggedIn;
  }),
  setLoginInputAction: action((state, input) => {
    state.auth.loginInput = {
      ...state.auth.loginInput,
      ...input,
    };
  }),
  setPasswordResetInputAction: action((state, input) => {
    state.auth.passwordResetInput = {
      ...state.auth.passwordResetInput,
      ...input,
    };
  }),
  setLoggedInInfoAction: action((state, input) => {
    localStorage.setItem("token", "");
    state.auth = {
      isLoggedIn: false,
      loginInput: {
        email: "",
        password: "",
      },
    };
  }),
};

export default actions;
