import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function useOnClick() {
  const {
    file: { document },
    setting,
  } = useStoreState((state) => state);

  const { createFileThunk } = useStoreActions((actions) => actions);

  return () =>
    createFileThunk({
      ...document,
      settingId: setting.document._id,
      successMsg: "Added new file",
    });
}

function ButtonSaveFile({ children = "Save File", ...props }) {
  const { name } = useStoreState((state) => state.file.document);
  const onClick = useOnClick();
  return (
    <Button disabled={!name} onClick={onClick} {...props}>
      {children}
    </Button>
  );
}

export default ButtonSaveFile;
