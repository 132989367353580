import { Col, Row } from "react-bootstrap";
import Field from ".";

function FieldList({ fields, ...props }) {
  return (
    <Row {...props}>
      {fields.map(({ col, ...field }, index) => (
        <Col key={index} {...col}>
          <Field {...field} />
        </Col>
      ))}
    </Row>
  );
}

export default FieldList;
