import { useStoreActions, useStoreState } from "easy-peasy";
import Field from "../../presentational/Field";

function useOnSubmit() {
  const { client, setting } = useStoreState((state) => state);

  const { searchSettingsThunk } = useStoreActions((actions) => actions);

  return (evt) => {
    evt.preventDefault();
    searchSettingsThunk({
      name: setting.searchInput,
      clientGuid: client.document.guid,
    });
  };
}

function useOnChange() {
  const { setSettingAction } = useStoreActions((actions) => actions);
  return (evt) => setSettingAction({ searchInput: evt.target.value });
}

function SearchFieldSetting(props) {
  const { searchInput } = useStoreState((state) => state.setting);

  const onChange = useOnChange();
  const onSubmit = useOnSubmit();

  return (
    <Field.Search
      onSubmit={onSubmit}
      onChange={onChange}
      placeholder="Enter setting name to search for"
      value={searchInput}
      {...props}
    />
  );
}

export default SearchFieldSetting;
