import { useStoreActions, useStoreState } from "easy-peasy";
import Document from "../../presentational/Document";
import Button from "../Button";
import Field from "../../presentational/Field";
import Center from "../../presentational/Center";
import Form from "../../presentational/Form";
import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";

const SForm = styled(Form)`
  max-width: 550px;
  background-color: #f8f9fa !important;
  ${tw`w-full p-8`}
`;

function useFields() {
  const passwordInput = useStoreState((state) => state.auth.passwordResetInput);
  const { setPasswordResetInputAction } = useStoreActions((actions) => actions);

  return [
    {
      label: "Password",
      stateProp: "password",
    },
    {
      label: "Confirm Password",
      stateProp: "cpassword",
    },
  ].map(({ stateProp, ...field }) => ({
    type: "password",
    value: passwordInput[stateProp],
    onChange: (evt) =>
      setPasswordResetInputAction({ [stateProp]: evt.target.value }),
    col: { xs: 12 },
    ...field,
  }));
}

function useOnSubmit() {
  const { password, cpassword } = useStoreState(
    (state) => state.auth.passwordResetInput
  );
  const { resetPasswordThunk } = useStoreActions((actions) => actions);
  const navigate = useNavigate();
  return (evt) => {
    evt.preventDefault();
    const params = new URLSearchParams(window.location.search);
    resetPasswordThunk({
      navigate,
      clientGuid: params.get("user"),
      password,
      cpassword,
    });
  };
}

function FormPasswordReset(props) {
  const onSubmit = useOnSubmit();
  const fields = useFields();

  return (
    <SForm onSubmit={onSubmit} {...props}>
      <Document.Header>
        <Center>Reset User's Password</Center>
      </Document.Header>
      <br />
      <Field.List fields={fields} />
      <br />
      <Button.PasswordReset />
    </SForm>
  );
}

export { FormPasswordReset as default, useFields };
