import { Button as RBButton } from "react-bootstrap";
import tw from "twin.macro";

const Container = tw.div`grid`;

function Button({ children, className, ...props }) {
  return (
    <Container className={className}>
      <RBButton {...props}>{children}</RBButton>
    </Container>
  );
}

export default Button;
