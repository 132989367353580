import tw from "twin.macro";
import Accordion from "../Accordion";
import Button from "../Button";

const Container = tw.div`flex justify-between`;

function DocumentHeaderClient({ eventKey = "1", ...props }) {
  return (
    <Container {...props}>
      <Accordion.Button eventKey={eventKey}>Client Document</Accordion.Button>
      <Button.AcumaticaUnconfirmed />
    </Container>
  );
}

export default DocumentHeaderClient;
