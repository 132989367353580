import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function useOnClick() {
  const { setting, client } = useStoreState((state) => state);

  const { createSettingThunk } = useStoreActions((actions) => actions);

  return () =>
    createSettingThunk({
      ...setting.document,
      successMsg: "Added new setting",
      clientGuid: client.document.guid,
    });
}

function ButtonSaveSetting({ children = "Save Setting", ...props }) {
  const { name } = useStoreState((state) => state.setting.document);
  const onClick = useOnClick();
  return (
    <Button disabled={!name} onClick={onClick} {...props}>
      {children}
    </Button>
  );
}

export default ButtonSaveSetting;
