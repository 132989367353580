import { Spinner } from "react-bootstrap";

function LoaderSpin({ children, ...props }) {
  return (
    <Spinner animation="border" {...props}>
      {children}
    </Spinner>
  );
}

export default LoaderSpin;
