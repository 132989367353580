import { useStoreActions, useStoreState } from "easy-peasy";
import Field from "../../presentational/Field";
import Editor from "@monaco-editor/react";
import { styled } from "twin.macro";

const EditorView = styled.div`
  place-items: center;
  width: 100%;
`;

const EditorHeader = styled.h1`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  margin-bottom: 10px;
`;

function useFileFields() {
  const { name } = useStoreState((state) => state.file.document);
  const { setFileDocumentAction } = useStoreActions((actions) => actions);
  return [
    {
      label: "Name (include file extension)",
      col: { xs: 12 },
      value: name,
      onChange: (evt) => setFileDocumentAction({ name: evt.target.value }),
      placeholder: "ex: file.txt",
      required: true,
    },
  ];
}

function useOnChange() {
  const { setFileDocumentAction } = useStoreActions((actions) => actions);
  return (value, evt) => setFileDocumentAction({ content: value });
}

function FormFile(props) {
  const { content } = useStoreState((state) => state.file.document);

  const fields = useFileFields();
  const onChange = useOnChange();

  return (
    <div {...props}>
      <Field.List fields={fields} />
      <EditorView>
        <EditorHeader />
        <Editor
          height="50vh"
          marginTop="10px"
          defaultLanguage="javascript"
          value={content}
          onChange={onChange}
          theme="vs-dark"
        />
      </EditorView>
    </div>
  );
}

export default FormFile;
