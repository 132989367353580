import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import Button from "../../presentational/Button";

function useOnClick() {
  const { user, client, auth } = useStoreState((state) => state);
  const { createDocumentsThunk } = useStoreActions((actions) => actions);
  const navigate = useNavigate();
  return () =>
    createDocumentsThunk({
      navigate,
      user: { ...user.document, ...auth.passwordResetInput },
      client: client.document,
    });
}

function ButtonAddDocuments({ children = "Add", ...props }) {
  const onClick = useOnClick();
  return (
    <Button onClick={onClick} {...props}>
      {children}
    </Button>
  );
}

export default ButtonAddDocuments;
