import { useStoreActions, useStoreState } from "easy-peasy";
import Document from "../../presentational/Document";
import Field from "../../presentational/Field";
import Button from "../../presentational/Button";
import tw, { styled } from "twin.macro";
import Center from "../../presentational/Center";
import Form from "../../presentational/Form";
import { useNavigate } from "react-router-dom";

const SForm = styled(Form)`
  background-color: #f8f9fa !important;
  ${tw`p-8`}
`;

function useFields() {
  const loginInput = useStoreState((state) => state.auth.loginInput);
  const { setLoginInputAction } = useStoreActions((actions) => actions);

  return [
    {
      label: "Email",
      placeholder: "abc@domain.com",
      stateProp: "email",
    },
    {
      label: "Password",
      placeholder: "abc123",
      stateProp: "password",
    },
  ].map(({ stateProp, ...field }) => ({
    col: { xs: 12 },
    onChange: (evt) => setLoginInputAction({ [stateProp]: evt.target.value }),
    value: loginInput[stateProp],
    type: stateProp,
    ...field,
  }));
}

function useOnSubmit() {
  const loginInput = useStoreState((state) => state.auth.loginInput);
  const { loginThunk } = useStoreActions((actions) => actions);
  const navigate = useNavigate();

  return (evt) => {
    evt.preventDefault();
    loginThunk({ ...loginInput, navigate });
  };
}

function FormLogin(props) {
  const fields = useFields();
  const onSubmit = useOnSubmit();

  return (
    <SForm onSubmit={onSubmit} {...props}>
      <Document.Header>
        <Center>Login</Center>
      </Document.Header>
      <br />
      <Field.List fields={fields} />
      <br />
      <Button type="submit">Login</Button>
    </SForm>
  );
}

export default FormLogin;
