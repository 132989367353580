import tw, { styled } from "twin.macro";
import DocumentForm from "./DocumentForm";
import DocumentHeader from "./DocumentHeader";
import DocumentSeparator from "./DocumentSeparator";

const Document = styled.div`
  max-width: 800px;
  padding: 5vh 0;
  ${tw`mx-auto w-full`}
`;

Document.Separator = DocumentSeparator;
Document.Header = DocumentHeader;
Document.Form = DocumentForm;
export default Document;
