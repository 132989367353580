import { useStoreActions, useStoreState } from "easy-peasy";
import Field from "../../presentational/Field";
import Accordion from "../Accordion";
import DocumentForm from ".";

function useUserFields() {
  const { name, role, email } = useStoreState((state) => state.user.document);
  const { setUserDocumentAction, setClientDocumentAction } = useStoreActions(
    (actions) => actions
  );

  return [
    {
      label: "Name",
      onChange: (evt) => {
        const name = evt.target.value;
        setUserDocumentAction({ name });
        setClientDocumentAction({ client: name });
      },
      value: name,
    },
    {
      label: "Role",
      onChange: (evt) => setUserDocumentAction({ role: evt.target.value }),
      value: role,
    },
    {
      label: "Email",
      onChange: (evt) => setUserDocumentAction({ email: evt.target.value }),
      value: email,
      type: "email",
      col: { xs: 12 },
    },
  ].map((field) => ({
    col: { xs: 12, md: 6 },
    ...field,
  }));
}

function DocumentFormUser({ children, eventKey = "0", ...props }) {
  const fields = useUserFields();
  return (
    <DocumentForm
      header={
        <Accordion.Button eventKey={eventKey}>User Document</Accordion.Button>
      }
      eventKey={eventKey}
      {...props}
    >
      <Field.List fields={fields} />
      {children}
    </DocumentForm>
  );
}

export default DocumentFormUser;
