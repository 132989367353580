import Button from "../../presentational/Button";
import ButtonAcumaticaUnconfirmed from "./ButtonAcumaticaUnconfirmed";
import ButtonAddDocuments from "./ButtonAddDocuments";
import ButtonAddFile from "./ButtonAddFile";
import ButtonAddSetting from "./ButtonAddSetting";
import ButtonLogout from "./ButtonLogout";
import ButtonPasswordReset from "./ButtonPasswordReset";
import ButtonSaveFile from "./ButtonSaveFile";
import ButtonSaveSetting from "./ButtonSaveSetting";

Button.Logout = ButtonLogout;
Button.AcumaticaUnconfirmed = ButtonAcumaticaUnconfirmed;
Button.AddSetting = ButtonAddSetting;
Button.AddFile = ButtonAddFile;
Button.SaveFile = ButtonSaveFile;
Button.SaveSetting = ButtonSaveSetting;
Button.PasswordReset = ButtonPasswordReset;
Button.AddDocuments = ButtonAddDocuments;
export default Button;
